@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: normal;
  src: local('Helvetica'), url('./fonts/Helvetica.otf') format('otf');
}

body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Helvetica, sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
    color: white;
  }
}